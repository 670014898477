
import { defineComponent, PropType } from 'vue'
import index from '@/views/Index.vue'
import { Instruction, IoBrief } from '../../../types/instruction_type'
import { AlertCategory, AlertColumnData, AlertColumnType, IState } from '../../../types/surcouche_v2_types'
import AlertModel from '@/models/Alertings/AlertModel'
import GroupKeyModel from '@/models/Alertings/GroupKeyModel'
import { getInfoError } from '../../../config/alertsCode'

export default defineComponent({
  name: 'AlertsColumn',
  computed: {
  },
  props: {
    io: {
      type: Object as PropType<IoBrief<Instruction>>
    },
    alerts: {
      type: Array as PropType<string[]>
    },
    groupKey: {
      type: Object
    }
  },
  data: function () {
    return {
      loading: true,
      alertsValue: {
        'fuck': {
          color: 'red',
          label: 'fuck',
          text: {},
          isOn: false,
          countError: 0
        },
        'brief': {
          color: 'red',
          label: 'brief',
          text: {},
          isOn: false,
          countError: 0
        },
        'endOfBilling': {
          color: 'red',
          label: 'end of billing',
          text: {},
          isOn: false,
          countError: 0
        },
        'pacing': {
          color: 'red',
          label: 'pacing',
          text: {},
          isOn: false,
          countError: 0
        },
        'checkPremep': {
          color: '#1976d2',
          label: 'check premep',
          text: {},
          isOn: false,
          countError: 0
        },
        'performance': {
          color: '#1976d2',
          label: 'performance',
          text: {},
          isOn: false,
          countError: 0
        },
        'abTest': {
          color: '#1976d2',
          label: 'ab test',
          text: {},
          isOn: false,
          countError: 0
        },
        'other': {
          color: '#1976d2',
          label: 'other',
          text: {},
          isOn: false,
          countError: 0
        }
      } as AlertColumnData
    }
  },
  methods: {
    async setAlertsBadge () {
      const apiCategories: AlertCategory[] = await this.$store.getters.getSurcoucheV2AlertCategoriesItems
      if (this.alerts && this.alerts.length !== 0) {
        this.alerts.map((alertErrors: string) => {
          apiCategories.map((category: AlertCategory) => {
            const errorType = category.error_type.split(',')
            if (errorType.includes(alertErrors)) {
              const categoryName: AlertColumnType = category.name
              this.alertsValue[categoryName].isOn = true
              this.alertsValue[categoryName].countError += 1
              errorType.map((error) => {
                let groupKeyAlert: AlertModel[] = this.groupKey.alerts[error]
                if (groupKeyAlert !== undefined) {
                  this.alertsValue[categoryName].text[error] = this.getAlertText(groupKeyAlert[0]) + '<br>'
                }
              })
            }
          })
        })
      }
    },
    getAlertText (alert: AlertModel) : string {
      let template = (this.$getInfoError(alert.error_type)).mainData

      if (template === undefined) {
        return 'NC'
      }
      return template(alert.data, alert.settings, alert.group_key)
    },
    getOpacity (index: string) {
      if (index === 'brief' || index === 'performance') {
        return { opacity: 0.75 }
      } else if (index === 'endOfBilling' || index === 'abTest') {
        return { opacity: 0.50 }
      } else if (index === 'pacing' || index === 'other') {
        return { opacity: 0.25 }
      } else {
        return { opacity: 1 }
      }
    },
    resetAlertValue () {
      this.alertsValue = Object.entries(this.alertsValue).reduce<AlertColumnData>((acc, [key, value]) => {
        acc[key as keyof AlertColumnData] = { ...value, isOn: false, text: {} }
        return acc
      }, {} as AlertColumnData)
    },
    resetCount () {
      for (const [key, value] of Object.entries(this.alertsValue)) {
        this.alertsValue[key as AlertColumnType].countError = 0
      }
    }
  },
  watch: {
    alerts: {
      deep: true,
      immediate: true,
      handler: async function (value) {
        this.loading = false
        this.resetCount()
        this.resetAlertValue()
        await this.setAlertsBadge()
      }
    }
  }
})
